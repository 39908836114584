import React from 'react';
import { Link } from 'react-router-dom';
import { getAllPartners } from '../../action/partner/getAllPartner';
import { getIndustriesList } from '../../action/Industries/getIndustriesList';
import { getIndustriesLocation} from '../../action/Industries/getIndustriesLocation';
import PartnerList from './partnerList';
import Loader from '../loader/loader';
import {getPartnerSearch} from '../../action/partner/getPartnerSearch';
import { connect } from "react-redux";
import CategoryList from '../category/categoryList';
import { getCategorieSearch } from '../../action/partner/getCategorieSearch';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMUserIdAndLoginState } from '../../utils/GtmDatalayer';
import { PropTypes } from 'prop-types';

export class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.searchCall=React.createRef();
        this.state = { 
            allPartnerDataList:null,
            selectedCategoryList:null,
            categoryList:null,
            categoryListFilter:null,
            locationList:null,
            loading:false,
            value:'',
            filterEnabled:false,
            checkedItems: new Map(),
            checkedLocation:new Map(),
            categoryFinalList:null,
            length:0,  //based to reload default partner list 
            isCheckedAllStore:true,
            categoriesListSearch:'',
            locationSelectedList:'',
            searchValue:'',
            checkStatus:false,
            allCheckStatus:false,
            offerCheckStatus:false,
            redirect:false,
            showCategories:true,
            filterStatus:'Open Filters'
         }
         this.handleFilter =this.handleFilter.bind(this);
         this.goBack = this.goBack.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.handleLocationChange = this.handleLocationChange.bind(this);
         this.favoritePartnerOnClickEvent = this.favoritePartnerOnClickEvent.bind(this);
         this.viewedPartnerOnClickEvent = this.viewedPartnerOnClickEvent.bind(this);
    }

    goBack(e){
       analyticsClickEvent('shop_back','')
        this.setState({showCategories:true});
        localStorage.setItem('showCategories','true');
        this.setState({categoriesListSearch:''});
        this.setState({locationSelectedList:''});
        this.setState({checkedItems:new Map()});
        this.setState({checkedLocation:new Map()});
        this.setState({searchValue:''});
        this.setState({checkStatus:false});
        this.setState({allCheckStatus:false});
        this.setState({offerCheckStatus:false});
        this.initialLoad();
    }

    componentDidMount(){
        
        this.setState({showCategories:true});
        localStorage.setItem('showCategories','true');
        this.initialLoad();

        // Push the customer id and loginState if available on this page to the dataLayer
        window.dataLayer = window.dataLayer || {};
        GTMUserIdAndLoginState();
    }

    initialLoad=async()=>{

        let partnerCategory=[];
        let partnerLocation=[];
        
        const objTemp={email:localStorage.getItem('email')}
        

         let categoryList = this.props.allIndustriesListStore
         if(!categoryList){
            this.setState({loading:true});
            categoryList = await this.props.getIndustriesList();
            categoryList = categoryList.data;
         }

        categoryList.data.map((list) => {
            partnerCategory.push(list);
        });
        this.timer = setTimeout(() => {
            this.setState({ loading: false });
        }, 0);
        console.log(this.timer);
        let parterCatSort=partnerCategory.sort((a, b) => a > b ? 1 : -1);
        this.setState({ categoryList: partnerCategory, categoryListFilter:parterCatSort});
        await this.props.getAllPartners(objTemp);
        const categoryLocation = await getIndustriesLocation();
        categoryLocation.data.data.map((location) => {
            partnerLocation.push(location);
        });

      
            this.setState({locationList:partnerLocation}); 
        }


    handleFilter(){
       analyticsClickEvent('filter_by','')
        this.setState({filterEnabled:!this.state.filterEnabled});

        if(this.state.filterEnabled===true && this.state.showCategories===true){
            if (this.searchCall && this.searchCall.current) {

            this.searchCall.current.categoryChange('category-img-Qed');
            }
            this.setState({filterStatus:'Open Filters'});


        }else if(this.state.filterEnabled===false && this.state.showCategories===true){
            if (this.searchCall && this.searchCall.current) {

            this.searchCall.current.categoryChange('category-img-Qed-split');
            }
            this.setState({filterStatus:'Close Filters'});
        }
        this.getCategories();
    }

    handleChange = e => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        analyticsClickEvent('filter_by','')
        if(e.target.name === 'all'){
        
            this.setState({allCheckStatus:isChecked});
        }
        
        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));
        
        setTimeout(() => {
            const userSelectedCategoryList = this.state.checkedItems;
            const selectedList=[];
                   for (let [key, value] of userSelectedCategoryList) {
                       if(value===true){
                           selectedList.push('~'+key);
                       }
                   }
                  let selectedVal= selectedList.toString();
                  this.setState({categoriesListSearch:selectedVal});
        }, 100);
        this.getFilterValues();
           
    }

    handleLocationChange = e => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        if(isChecked === true){
               analyticsClickEvent('checkbox_'+item+'_checked','')
            }else{
               analyticsClickEvent('checkbox_'+item+'_unChecked','')
            }
        this.setState(prevState => ({
            checkedLocation: prevState.checkedLocation.set(item, isChecked)
        }));
        
        setTimeout(() => {
            const userSelectedCategoryList = this.state.checkedLocation;
            const selectedList=[];
                   for (let [key, value] of userSelectedCategoryList) {
                       if(value===true){
                           selectedList.push('~'+key);
                       }
                   }
                  let selectedVal= selectedList.toString();
                  this.setState({locationSelectedList:selectedVal});
        }, 100); 

        this.getFilterValues();     
    }
  

    statusChange=(event)=>{
        const item = event.target.name;
        const isChecked = event.target.checked;
        if(isChecked === true){
               analyticsClickEvent('checkbox_'+item+'_checked','')
            }else{
               analyticsClickEvent('checkbox_'+item+'_unChecked','')
            }
        if(event.target.name === 'offers'){
            const isChecked = event.target.checked;
            this.setState({offerCheckStatus:isChecked}) 
        }else if(event.target.name === 'IsOnline'){
            const isChecked = event.target.checked;
            this.setState({checkStatus:isChecked})
        }
        
        this.getFilterValues();
    }



    handleSearchChange=async(event)=>{
        // analyticsTypeEvent('search_partners')
        const target = event.target;
         const value = target.value;
        const name = target.name;
        
        if(localStorage.getItem('showCategories')==='false'){
            this.setState({ [name]: value},()=>{
                this.getFilterValues();
              });  
        }else{
             this.setState({ [name]: value},()=>{
                 this.getCategorieFilterValues();
              });
        }
    }
    
    handleKeyDown = event => {
        if (event.key === 'Enter') {
                 event.preventDefault()
        }
    }

    getLocation=()=>{
        let location=this.state.locationSelectedList;
            if(this.state.categoriesListSearch.indexOf("all")>= 0){
                 location='';
            }
            return location;
    }

    getCategories=()=>{
        let catogries=this.state.categoriesListSearch;
        if(this.state.categoriesListSearch.indexOf("all")>= 0){
             catogries="";
        }

        if(this.state.searchValue!==''  && this.state.locationSelectedList===''){
            catogries=this.ListCategories(catogries);
        }

        if(this.state.searchValue===''  && this.state.locationSelectedList===''){
            catogries=this.ListCategories(catogries);
        }
        return catogries;
    }

    ListCategories=(catogries)=>{
        if(catogries==='' || catogries==='all'){
            catogries="";
        }
        return catogries;
    }

    getFilterValues=async()=>{
        if(this.state.showCategories===true){
            this.setState({loading:true});
            this.setState({searchValue:''});
        }
        this.setState({showCategories:false})
        localStorage.setItem('showCategories','false');

        setTimeout(async() => {
            let isOnline = false;
            let offers = false;
            const { allCheckStatus, checkStatus, offerCheckStatus } = this.state;

            if (allCheckStatus) {
            this.setState({ isOnline: false, offers: false });
            } else {
            isOnline = checkStatus;
            offers = offerCheckStatus;
            }

            let catogries=this.getCategories();
            let location=this.getLocation();
            const obj={
                'Industry':catogries,
                'Location':location,
                'IsOnline':isOnline,
                'Keywords':this.state.searchValue,
                "offers":offers,
                'email':localStorage.getItem('email'),
            }
            const result=await this.props.getPartnerSearch(obj);
            if(result.data.statusCode === 429){
                this.props.history.push('/shop');
            }
            this.setState({loading:false});

                let finalResult=[];
                this.setState({allPartnerDataList:null});
                finalResult = await this.setParterDetailsFromApiResponse(result,finalResult);
                finalResult.sort((f1, f2) => {                    
                    if(f1.partnerName.toLowerCase() > f2.partnerName.toLowerCase()) {
                        return 1;
                    }
                    if(f1.partnerName.toLowerCase() < f2.partnerName.toLowerCase()){
                        return -1;
                    }
                    return 0;
                });
            this.setState({allPartnerDataList:finalResult});
                if (this.searchCall?.current && localStorage.getItem('showCategories')==='false' && this.state.redirect===false) {
                this.searchCall.current.partnerFilter();
                }
            }, 1000); 
    }

    setParterDetailsFromApiResponse = async(result,finalResult) => {
        if(result.data.data!==null){
                
            if (Array.isArray(result.data.data) && result.data.data.length !== 0) {
            await Promise.all(result.data.data.map( async(response) => {
              let pFd = finalResult.filter(pn => pn.partnerId == response.hummuk_account_id);
              if(pFd.length == 0){
               const dataObj={
                partnerId: response.hummuk_account_id,
                partnerName: response.hummuk_account_Name,
                partnerIndustry: response.hummuk_account_Industry,
                partnerLogo: response.hummuk_account_flxLogo__c,
                partnerCoverImage: response.hummuk_account_Cover_Image__c,
                partnerWebSite: response.hummuk_account_Website,
                partnerFavId: response.fav_id
               }
               finalResult.push(dataObj);
            }
             }));
            }
            }
            return finalResult;
    }


    favcall=()=>{
        setTimeout(async() => {
        this.getFilterValues();
        },100);
    }
    
    getCategorieFilterValues=async()=>{
            const obj={
                'Industry':'',
                'Location':'',
                'Keywords':'',
            }

        const result = await this.props.getIndustriesList();
        let finalResult = [];
        if (result.data.data !== null) {
            result.data.data.map((src) => {
                if (this.state.searchValue != '') {
                    let searchValueToLower = this.state.searchValue.toLowerCase();
                    let searchIndustry = src.toLowerCase();
                    if (searchIndustry.includes(searchValueToLower)) {
                        finalResult.push(src)
                    }
                } else {
                    finalResult.push(src)
                }
            });
        }
        let parterCatSort=finalResult.sort((a, b) => a > b ? 1 : -1);
        this.setState({ categoryListFilter: parterCatSort })
        if (this.searchCall && this.searchCall.current && localStorage.getItem('showCategories') === 'true') {
            this.searchCall.current.categoryFilter();
            }
    }

    redirect=(val,response)=>{
        this.setState({categoriesListSearch:response});
        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(response, true)
        }));
        this.getFilterValues();
     }

    favoritePartnerOnClickEvent(){
        this.setState({ redirect: true }); 
        analyticsClickEvent('favorite_partner_icon', '')
    }

    viewedPartnerOnClickEvent(){
        this.setState({ redirect: true }); 
        analyticsClickEvent('viewed_partner_icon', '')
    }

    displayFilter=()=>{
        return <div className={`modal-section-body ${this.state.filterEnabled?"custom-cat-display":"custom-cat-none"}`}>
        <div className="custom-check-box-left">
        <div className="category-item">
            <h3>Category</h3>
            <form>
                <div className="form-check checkbox-grid-item">
                  <input type="checkbox" name="all" value="all" onChange={this.handleChange}   checked={this.state.allCheckStatus===true}className="form-check-input"/>
                  <label className="form-check-shopLabel" htmlFor="exampleCheck1"></label>
                  <span className="label-text-field">All</span>
                </div>

                <ul>
                { this.state.categoryList &&
                this.state.categoryList.map((response,index) => 
                <li key={response.toString()}>


                                        <div className="form-check checkbox-grid-item">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`custom-checkbox-${index}`}
                                                name={response}
                                                checked={this.state.categoriesListSearch.indexOf('~' + response) >= 0}
                                                // vaue={index}
                                                value={response}
                                                onChange={this.handleChange}
                                            />

                <label className="form-check-shopLabel" htmlFor="exampleCheck1"></label>
                <span className="label-text-field">{response}</span>
                </div></li>) 
                } 
            </ul>
            </form>
        </div>
        { Array.isArray(this.state.locationList) && this.state.locationList.length!=0 &&
        <div className="category-item">
            <h3>Location</h3>
            <form>
                <ul>
                { this.state.locationList &&
                this.state.locationList.map((response,index) => 
                <li key={response.toString()}>

                 {response!='' && response!=null && response!=undefined &&
                 <div className="form-check checkbox-grid-item">
                 <input
                     type="checkbox" 
                     className="form-check-input"
                     id={`custom-checkbox-${index}`}
                     name={response}
                     value={response}
                    checked={this.state.locationSelectedList.indexOf('~'+response)>= 0}
                     onChange={this.handleLocationChange}  
                 />
 
                 <label className="form-check-shopLabel" htmlFor="exampleCheck1"></label>
                <span className="label-text-field">{response}</span>
                 </div>
                 }
                </li>) 
                } 
                </ul>
            </form>
        </div>
    }
        <div className="category-item">
                    <h3>Status</h3>
                    <form>
                        <ul>
                            <li>
                            {this.state.checkStatus}
                            <div className="form-check checkbox-grid-item">
                                <input
                                    type="checkbox" 
                                    className="form-check-input"
                                    id='custom-checkbox'
                                    name='IsOnline'
                                    value='IsOnline'
                                    checked={this.state.checkStatus===true}
                                    onChange={this.statusChange}  
                                    

                                />

                                <label className="form-check-shopLabel" htmlFor="exampleCheck1"></label>
                                <span className="label-text-field">Online</span>
                                </div>
                            </li>
                            <li>
                            {this.state.checkStatus}
                            <div className="form-check checkbox-grid-item">
                                <input
                                    type="checkbox" 
                                    className="form-check-input"
                                    id='custom-checkbox'
                                    name='offers'
                                    value='offers'
                                    checked={this.state.offerCheckStatus===true}
                                    onChange={this.statusChange}  
                                    

                                />

                                <label className="form-check-shopLabel" htmlFor="exampleCheck1"></label>
                                <span className="label-text-field">Offers</span>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
        </div>    
        {/* end cat */}
    </div>
    }
     
    shopFloatingQuoteToolClickEvent(){
        localStorage.setItem('prevRoute',window.location.pathname)
        analyticsClickEvent("common_quote_calculator", "")
    }
    
    render() { 
        if(localStorage.getItem('webView') != ''){
            localStorage.removeItem('webView');
        }
        return (
            <div className={`wrapper  ${this.state.filterEnable ? 'custom-model' : ''}`}  >
                <Loader loaderStatus={this.state.loading} />
                <section className="main">
                    <div className="search-fav-section">
                        <div className="container">
                            <div className="d-flex align-items-center top-section-partner">
                                <div className="left-iteQerd">
                                    {this.state.showCategories === false &&
                                        <div className="back-btn-item">
                                            <a className="back-btnQr" onKeyDown={(e) => { e.preventDefault(); }} onClick={(e) => this.goBack(e)}><img src="images/back.svg" alt="<-" /> Back </a>
                                        </div>
                                    }
                                </div>
                                <div className="right-iteQerd">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <form className="search-iconbanner">
                                                <div className="form-group m-0">
                                                    <input className="form-control" name="searchValue" value={this.state.searchValue} placeholder="Search" onChange={this.handleSearchChange} onBlur={(e) => analyticsClickEvent('shop_search', this.state.searchValue)} onClick={(e) => analyticsClickEvent('shop_search', '')} onKeyDown={this.handleKeyDown} type="text" />
                                                    <span className="search-icon"><img src="images/search-Icon-white.svg" alt="search" /></span>
                                                </div>
                                            </form>
                                        </div>
                                        {localStorage.getItem('fromWhere') != 'skip' &&
                                            <div className="fav-eye-item">
                                                <div className="icon-itemQer">
                                                    <Link to="/favorite-partner" onClick={this.favoritePartnerOnClickEvent}>
                                                        <img src="images/fav-icon.svg" alt="fav-icon" /></Link>
                                                </div>
                                                <div className="icon-itemQer">
                                                    <Link to="/viewed-partner" onClick={this.viewedPartnerOnClickEvent}>
                                                        <img src="images/eye-icon.svg" alt="eye-icon" /></Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container custom-flex">

                        {this.displayFilter()}
                        {localStorage.getItem('showCategories') === 'false' &&
                            <div className={`filter-itemQed  ${this.state.filterEnabled ? "custom-cat-display-grid" : "custom-cat-none-grid"}`}>
                                <div className="d-flex">
                                    <div className="filter-content">
                                        <p>{this.state.filterEnabled ? "Close Filters" : "Open Filters"}</p>
                                    </div>
                                    <button type="button" className="filter-btn" data-toggle="modal" data-target="#filter" onClick={this.handleFilter}>
                                        <img src="images/filter-icon.svg" />
                                    </button>
                                </div>
                                <div className="image-gallery">
                                    <div className="featured-partners-list row">

                                        {this.state.allPartnerDataList &&

                                            <PartnerList itemList={this.state.allPartnerDataList} ref={this.searchCall} favCall={this.favcall} />
                                        }
                                         {/* //vinoth changes start */}
                                        {this.state.allPartnerDataList == '' &&
                                            <div style={{ width: '100%', marginTop: '50px' }}>
                                                <p style={{ color: '#4a4a4a', fontFamily: "ProximaNova-Bold", fontSize: '23px', fontStyle: 'normal', letterSpacing: 'normal', lineHeight: 'normal', textAlign: 'center', width: '100%', margin: '0 auto', marginTop: '30px' }}>No Partners Found</p>
                                            </div>

                                        }
                                         {/* //vinoth changes end */}

                                    </div>
                                </div>
                            </div>
                        }
                        {localStorage.getItem('showCategories') === 'true' &&
                            <div className={`filter-itemQed  ${this.state.filterEnabled ? "custom-cat-display-grid" : "custom-cat-none-grid"}`}>
                                <div className="d-flex category-filter">
                                    <div className="category-content">
                                        <h3>Shop fashion, beauty, electrical, appliances and much more! <br></br>Pay later in easy instalments.</h3>
                                    </div>
                                    <div className="ml-auto d-flex">
                                        <div className="filter-content">
                                            <p>{this.state.filterStatus}</p>
                                        </div>
                                        <button type="button" className="filter-btn" data-toggle="modal" data-target="#filter" onClick={this.handleFilter}><img src="images/filter-icon.svg" /></button>
                                    </div>
                                </div>
                                <div className="category-gallery">
                                    <div className="category-unlist row">
                                        {this.state.categoryList &&

                                            <CategoryList itemList={this.state.categoryListFilter} ref={this.searchCall} redirectPage={this.redirect} clearCheckList={this.goBack} />
                                        }
                                         {/* //vinoth changes start */}
                                        {this.state.categoryListFilter == '' &&
                                            <div style={{ width: '100%', marginTop: '50px' }}>
                                                <p style={{ color: '#4a4a4a', fontFamily: "ProximaNova-Bold", fontSize: '23px', fontStyle: 'normal', letterSpacing: 'normal', lineHeight: 'normal', textAlign: 'center', width: '100%', margin: '0 auto', marginTop: '30px' }}>No Category Found</p>
                                            </div>
                                        }
                                         {/* //vinoth changes end */}
                                    </div>
                                </div>
                            </div>
                        }



                    </div>
                    <div className="floating-pageicon">
                        <div className="calculator-icon">
                            <Link
                            to="/quote-calculator"
                            onClick={this.shopFloatingQuoteToolClickEvent}
                            >
                            <span>
                                <img src="images/calculator.svg" alt="icon" />
                            </span>
                            </Link>
                        </div>
                    </div>
                </section>

    </div>
   
);
    }
}

export const mapStateToProps = (state) => {
    return {
        allpartnerListStore: state.dataStore.allpartnerListStore,
        allpartnerListSearchStore: state.dataStore.allpartnerListSearchStore,
        allCategoryListSearchStore: state.dataStore.allCategoryListSearchStore,
        allIndustriesListStore: state.dataStore.allIndustriesListStore,
        storePartnerImage: state.dataStore.storePartnerImage,
    };
   };


  export const mapDispatchToProps = (dispatch) => {
    return {
        getAllPartners: (formData) => {
            return dispatch(getAllPartners(formData));
        },
        getPartnerSearch: (formData) => {
            return dispatch(getPartnerSearch(formData));
        },
        getCategorieSearch: (formData) => {
            return dispatch(getCategorieSearch(formData));
        },
        getIndustriesList:() => {
            return dispatch(getIndustriesList());
        },
        storePartnerImagefunction:(formData)=>{
            return dispatch({
                type: 'STOREPARTNERIMAGE',
                value: formData
            });
        }
    }
   }

Partner.propTypes = {
    getIndustriesList: PropTypes.func,
    allIndustriesListStore: PropTypes.array,
    getAllPartners: PropTypes.func,
    getPartnerSearch: PropTypes.func,
    history: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
