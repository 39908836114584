import React from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import range from 'lodash/range';
import moment from "moment";
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { GTMFieldErrorChange, GTMFieldValidChange } from "../../../utils/GtmDatalayer";
import { PropTypes } from 'prop-types';
import { convertDateFormat } from "../../../utils/convertDateFormat";
import Cookies from "universal-cookie";
import { frontEndLogs } from "../../../action/logs/frontEndLogs";
export class InputDatePicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      dateType: '',
      time_in_occupation: "",
      changeTexts: false,
      errors: {
      },
      input: {

      },
      stages: {

      }, 
      showValidation: this.props.showAppValidations, 
      startYear: 1922, 
      fieldname: this.props.field_api_name, 
      showDev: {},
      showTickmarkForDate: false
    }
  }

  checkDateMovedInValidation = (errors,stages,saveDate,date,dateMovedin)=>{
    if (this.props.field_api_name === 'date_moved_in_current_address') {
      const allDetails = Object.assign({}, ...this.props.applicationValidation);
      if (allDetails['flxBirthDate__c'] !== '' && allDetails['flxBirthDate__c']!=undefined) {
        errors['date_moved_in_current_address'] = 'Please select the correct Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Please select the correct Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }

      if (!this.props.saveDates) {
        errors['date_moved_in_current_address'] = 'Please select the Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Please select the Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }

      const dates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = dates.format('MMM-YYYY');
      const movedDate = moment(saveDate, 'MM/yyyy');
      const movedmonth = movedDate.format('MMM-YYYY');
       const isEarlierThanDob = this.dateConversion(month,movedmonth);
      if (isEarlierThanDob==='true') {
        errors['date_moved_in_current_address'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Date must not be earlier than your Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        date = '';
      }
      if (month > movedmonth) {
        this.props.saveDateMovedIn(dateMovedin)
      }

      this.props.saveDateMovedIn(dateMovedin);
      let input = this.state.input;
      let inputValues = {}
      input[this.props.field_api_name] = date;
      inputValues[this.props.field_api_name] = dateMovedin
      if(stages.date_moved_in_current_address==undefined){
        this.HandlingValidation()
      }else{
        return false;
      }
    }

  }

  checkTimeInOccupationValidation = (saveDate,date) => {
    if (this.props.field_api_name === 'time_in_occupation') {
      const bDates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = bDates.format('MMM-YYYY');
      const dates = moment(saveDate, 'MM/yyyy');
      const tio = dates.format('MMM-YYYY');
      let tioerrors = this.state.errors;
      let tiostages = {};
      const isEarlierThanDob = this.dateConversion(month,tio);
      if (isEarlierThanDob==='true') {
        let tinput = this.state.input;
        tioerrors['time_in_occupation'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          tioerrors
        });
        tiostages['time_in_occupation'] = 'Date must not be earlier than your Date of Birth';
        tiostages['stage'] = this.props.stage;
        this.props.appvalidations(tiostages);
        let tinputValues = {};
        tinput[this.props.field_api_name] = date;
        tinputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(tinputValues)
        this.setState({
          tinput
        });
        return false;
      }else {
        let tinput = this.state.input;
        tioerrors['time_in_occupation'] = '';
        this.setState({
          tioerrors
        });
        tiostages['time_in_occupation'] = '';
        tiostages['stage'] = this.props.stage;
        this.props.appvalidations(tiostages);
        let tinputValues = {};
        tinput[this.props.field_api_name] = date;
        tinputValues[this.props.field_api_name] = saveDate;
        this.props.saveApplicationDatas(tinputValues)
        this.setState({
          tinput
        });
        return false;
      }
    }
  }

  checkBankHourseValidation = (saveDate,date) => {
    if (this.props.field_api_name === 'genesis__bank_hours__c') {
      const bDates = moment(this.props.saveDates, 'dd/MM/yyyy');
      const month = bDates.format('MMM-YYYY');
      const dates = moment(saveDate, 'MM/yyyy');
      const tio = dates.format('MMM-YYYY');
      let gerrors = this.state.errors;
      let gstages = {};
      const isEarlierThanDob = this.dateConversion(month,tio);
      if (isEarlierThanDob==='true') {
          gerrors['genesis__bank_hours__c'] = 'Date must not be earlier than your Date of Birth';
          this.setState({
            gerrors
          });
          gstages['genesis__bank_hours__c'] = 'Date must not be earlier than your Date of Birth';
          gstages['stage'] = this.props.stage;
          this.props.appvalidations(gstages);
          let ginputValues = {}
          let ginput = this.state.input;
          ginput[this.props.field_api_name] = date;
          ginputValues[this.props.field_api_name] = saveDate;
          this.props.saveApplicationDatas(ginputValues)
          this.setState({
            ginput
          });
          return false;
        } else {
          let ginput = this.state.input;
        
          gerrors['genesis__bank_hours__c'] = '';
          this.setState({
            gerrors
          });
          gstages['genesis__bank_hours__c'] = '';
          gstages['stage'] = this.props.stage;
          this.props.appvalidations(gstages);
          let ginputValues = {};
          ginput[this.props.field_api_name] = date;
          ginputValues[this.props.field_api_name] = saveDate;
          this.props.saveApplicationDatas(ginputValues)
          this.setState({
            ginput
          });
          return false
        }
    }
  }

  checkBirthDateFieldValidation = (date,saveDate) => {
    if (this.props.field_api_name === 'flxBirthDate__c') {
      if (!date) {
        date = '';
        let errors = this.state.errors;
        let stages = {};
        errors['flxBirthDate__c'] = 'This is Required';
        stages["date_moved_in_current_address"] = 'This is Required';
        this.setState({
          errors
        });
        stages["flxBirthDate__c"] = 'This is Required';
        stages["date_moved_in_current_address"] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.props.saveDate(saveDate)
      }

      const dates = moment(saveDate, 'dd/MM/yyyy');
      const month = dates.format('YYYY');
      const movedDate = moment(this.props.saveDatesMovedIn, 'MM/yyyy');
      const movedyear = movedDate.format('YYYY');
      if (month > movedyear && month !== "Invalid date") {
        let errors = this.state.errors;
        let stages = {};
        errors['date_moved_in_current_address'] = 'Date must not be earlier than your Date of Birth';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = 'Date must not be earlier than your Date of Birth';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
      }
      // 155 126
      else if (month < movedyear && month !== "Invalid date") {
        let errors = this.state.errors;
        let stages = {};
        errors['date_moved_in_current_address'] = '';
        this.setState({
          errors
        });
        stages["date_moved_in_current_address"] = '';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
      }
      // 140 // 83 
      else if (month === "Invalid date" || month == undefined) {
        let errors = this.state.errors;
        let stages = {};
        errors['flxBirthDate__c'] = 'This is Required';

        this.setState({
          errors
        });
        stages["flxBirthDate__c"] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);

        this.props.saveDate(saveDate)
      }
      this.props.saveDate(saveDate)
    }
    return date;
  }

  setStartDate = async(date) => {
    this.setState({ changeTexts: true })
    let saveDate = '';
    let dateMovedin = '';
    this.setState({ showValidation: 'yes' });
    let errors = this.state.errors;
    let stages = {};

   
    if (date === null) {
      date = '';
    } else {
      saveDate = format(date, this.state.dateType)
      dateMovedin = format(date, this.state.dateType)
    }

    let checkResult = this.checkDateMovedInValidation(errors,stages,saveDate,date,dateMovedin);
    if(checkResult === false) {
      return checkResult;
    }

    let checkTimeInocupationResult = this.checkTimeInOccupationValidation(saveDate,date);
    if(checkTimeInocupationResult === false) {
      return checkTimeInocupationResult;
    }

    let checkBankHourseResult = this.checkBankHourseValidation(saveDate,date);
    if(checkBankHourseResult === false){
      return checkBankHourseResult;
    }

    let input = this.state.input;
    let inputValues = {}
    input[this.props.field_api_name] = date;
    inputValues[this.props.field_api_name] = saveDate;
    this.props.saveApplicationDatas(inputValues)
    this.setState({
      input
    });
    date = this.checkBirthDateFieldValidation(date,saveDate);
    this.setState({ startDate: date });
    setTimeout(() => {
      if(this.props.field_api_name === 'Birth_Date'){
        this.onDateClickOutside();
      }
    }, 500);
    
    this.HandlingValidation();

    setTimeout(() => {
      const { applicationValidation, field_label, field_api_name } = this.props;
      const latestEntry = [...applicationValidation].reverse().find(value => value.hasOwnProperty(field_api_name));
      if (latestEntry && latestEntry[field_api_name] !== "") {
        GTMFieldErrorChange(field_label);
      } else {
        GTMFieldValidChange(field_label);
      }
    }, 300);
  }


  componentDidMount() {
    let questionValues={};
    questionValues['field_api_name']=this.props.field_api_name;
    questionValues['priority']=this.props.priority;
    questionValues['stage']=this.props.stage;
    questionValues['field_question'] = this.props.field_question;
    if(this.props.field_api_validations.length!=0){
      questionValues['validation']='yes';
    }else{
      questionValues['validation']='no';
    }
    this.props.questionSave(questionValues);
    
    if (this.props.field_placeholder === 'DD/MM/YYYY') {
      this.setState({ dateType: 'dd/MM/yyyy' })
    }
    if (this.props.field_placeholder === 'MM/YYYY') {
      this.setState({ dateType: 'MM/yyyy' })
    }
    this.props.saveDateMovedIn('');
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stages = {};
      stages[this.props.field_api_name] = '';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }
    this.prepopulateDobinPurchaseFlow();

    setTimeout(() => {
      this.validationOfPrepopulatedDate();
    }, 800);
  }

  prepopulateDobinPurchaseFlow = () => {
    const cookie = new Cookies();
    if(cookie.get('sourceURL') || cookie.get('retailerId')){
    if (this.props.field_api_name === 'Birth_Date') {
      let prepopulateValues = {}
      let fieldValues = {}, stageValue = {};
      frontEndLogs('Purchase link cookie DOB params ',false,200,'prepopulateDobinPurchaseFlow',{DOB:cookie.get('Dob'),street:cookie.get('numberAndStreet'),postcode: cookie.get('postcode'),townOrCity: cookie.get('townOrCity'),addressLine2: cookie.get('addressLine2')});
    if(cookie.get('Dob') !== '' && cookie.get('Dob') !== undefined) {
      this.setState({showTickmarkForDate: true});
      this.props.saveDate(convertDateFormat(cookie.get('Dob')));
      prepopulateValues['Birth_Date'] = convertDateFormat(cookie.get('Dob'))
      fieldValues['Birth_Date'] = '';
    stageValue['Birth_Date'] = true;

    this.props.appvalidations(fieldValues);
    this.props.firstSectionValidationUpdate(stageValue)
    this.props.saveApplicationDatas(prepopulateValues)
    }
    
  }
}
  }

  prepopulateValueForDate = (prepopulateInput, allDetailData) => {
    if (prepopulateInput[this.props.field_api_name] !== allDetailData[this.props.field_api_name] && allDetailData[this.props.field_api_name] !== undefined && allDetailData[this.props.field_api_name] !== null) {
        let monthYearDate = allDetailData[this.props.field_api_name];
        if (allDetailData[this.props.field_api_name].replace('/', '').length === 6) {
          let monthDate = allDetailData[this.props.field_api_name].split('/');
          monthYearDate = monthDate[0] + '/' + allDetailData[this.props.field_api_name];
        }
        if (allDetailData[this.props.field_api_name].replace('/', '').length === 9) {
          let dobDate = allDetailData[this.props.field_api_name].split('/');
          monthYearDate = dobDate[1] + '/' + dobDate[0] + '/' + dobDate[2];
        }
        let prepopulateDateValue = new Date(monthYearDate);
        prepopulateInput[this.props.field_api_name] = prepopulateDateValue;
        this.setState({
          prepopulateInput
        });
      }
  }

  validationOfPrepopulatedDate = () => {
    let fieldValues = {}, stageValues = {}
    let allDetailData = Object.keys({}, ...this.props.saveApplicationData);
    if(allDetailData['Birth_Date'] !== '' && allDetailData['Birth_Date'] !== null  && allDetailData['Birth_Date'] !==  undefined && (!this.props.editable && this.props.prepopulateStatus) || this.props.birthDateFlag) {
      stageValues['Birth_Date'] = ''
      fieldValues['Birth_Date'] = true
      this.setState({showTickmarkForDate: true});

      this.props.appvalidations(stageValues);
      this.props.firstSectionValidationUpdate(fieldValues)
    }
  }

  showFieldsBasedOnLoanAmount = (dataProps) => {
    if (dataProps.amountRequested.split(',').join('').length > 1) {
      if (dataProps.amountRequested.split(',').join('') > dataProps.threashold) {
        if (dataProps.priority === 3) {
          let showDev = this.state.showDev;
          showDev[this.state.fieldname] = true;
          this.setState({
            showDev
          });

        }
      } else if(dataProps.priority === 3 && dataProps.amountRequested !== '') {
          let showDev = this.state.showDev;
          showDev[this.state.fieldname] = false;
          this.setState({
            showDev
          });
          let input = this.state.input;
          input[this.state.fieldname] = '';
          this.setState({
            input
          });

      }
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.prepopulateStatus === true && this.state.changeTexts === false) {
      const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
      let prepopulateInput = this.state.input;
      this.prepopulateValueForDate(prepopulateInput, allDetailData)
    }

    if (nextProps.userDetailsPopulateStatus === true && this.state.changeTexts === false) {
      const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
      let prepopulateInput = this.state.input;
      this.prepopulateValueForDate(prepopulateInput, allDetailData)
    }
      setTimeout(() => {
        this.changePurchaseDateFormat();
      }, 100);
    
    if (nextProps.showAppValidations !== this.state.showValidation) {
      if (nextProps.showAppValidations !== '' || nextProps.stage === '2') {
        this.setState({ showValidation: nextProps.showAppValidations })
      }
    }
    if (nextProps.saveDates !== this.state.startYear && nextProps.saveDates !== '') {

      let year = nextProps.saveDates.substring(nextProps.saveDates.lastIndexOf("/") + 1)
      this.setState({ startYear: year })
    }
    if (nextProps.saveDates === '' && this.state.input['date_moved_in_current_address'] !== '') {

      let input = this.state.input;
      input['date_moved_in_current_address'] = '';
      let inputValues = {}
      inputValues['date_moved_in_current_address'] = '';
      this.props.saveApplicationDatas(inputValues)
      this.setState({
        input
      });
    }

    this.showFieldsBasedOnLoanAmount(nextProps);

  }

  changePurchaseDateFormat = ()=>{
    const cookie = new Cookies();
    if(cookie.get('sourceURL') || cookie.get('retailerId')){
      if(cookie.get('Dob') !== '' && cookie.get('Dob') !== undefined) {
        const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
        if (this.props.field_api_name === 'Birth_Date') {
        let prepopulateInput = this.state.input
        this.prepopulateValueForDate(prepopulateInput, allDetailData)
      }
    }
  }
  }



  HandlingValidation = () => {
    let stages = {};
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    let errors = this.state.errors;
    errors[this.props.field_api_name] = '';
    this.setState({
      errors
    });
    this.props.appvalidations(stages);
    setTimeout(() => {
      this.props.field_api_validations.map((src) => {
        if (!this.state.startDate || this.state.dateMovedin === "") {
          errors[this.props.field_api_name] = 'This is Required';
          this.setState({
            errors
          });
          stages[this.props.field_api_name] = 'This is Required';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          return false;
        }

        if (this.state.startDate === undefined) {
          errors[this.props.field_api_name] = 'This is Required';
          this.setState({
            errors
          });
          stages[this.props.field_api_name] = 'This is Required';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          return false;
        }
        let checkAgeRangeResult = this.checkAgeRangevalidation(src,errors,stages);
        if(checkAgeRangeResult === false) {
          return false;
        }
      });
    }, 200);
  }

  checkAgeRangevalidation = (src,errors,stages)=>{
    if (src.flxType__c === 'Age Range') {
      let today = new Date();
      let birthDate = new Date(this.state.startDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      let d=today.getDate() - birthDate.getDate();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      let dobDateCheck=true;
      if(m === 0 && d === 0 && age === 100){
        dobDateCheck=false;
      }
      
      if (age < 18) {
        errors[this.props.field_api_name] = src.flxError_Message__c;
        this.setState({
          errors
        });
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }
      if (age >= 76 && dobDateCheck===true) {
        errors[this.props.field_api_name] = '';
        this.setState({
          errors
        });
        stages[this.props.field_api_name] = 'Age should be less than 76 year old';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        return false;
      }
    }
  }

  dateConversion=(dob,compareDate)=>{
    let dateComparisonResult='false';
    let dobMonthYearConversion= moment(dob,"MMM-YYYY");
    let compareDateMonthYearConversion= moment(compareDate,"MMM-YYYY");
    
    if(dobMonthYearConversion>compareDateMonthYearConversion){
      dateComparisonResult='true';
    }
    return dateComparisonResult;
  }

  nonEditableCheckForPrepopulate = (disableFieldValue, userAllDetailsData) => {
    if (this.props.editable === false && this.props.prepopulateStatus === true && userAllDetailsData[this.props.field_api_name] !== '' && userAllDetailsData[this.props.field_api_name] !== null && userAllDetailsData[this.props.field_api_name] !== undefined && this.props.prepopulateCondition === true) {
      disableFieldValue = 'nonEditable'
      return disableFieldValue
    }
  }

  nonEditableCheckForUserPrepopulate = (disableFieldValue, userAllDetailsData) => {
    if (this.props.editable === false && this.props.userDetailsPopulateStatus === true && this.props.birthDateFlag === true && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null  && userAllDetailsData[this.props.field_api_name] !== undefined && this.props.prepopulateCondition === true) {
      disableFieldValue = 'nonEditable'
    }
    
    if(this.props.editable === false && this.props.userDetailsPopulateStatus === true && this.props.birthDateFlag === false && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] !== undefined && userAllDetailsData[this.props.field_api_name] != null && this.props.prepopulateCondition === true) {
      disableFieldValue = ''
    }
    return disableFieldValue
  }

  nonEditableCheckForMobileCheck = (disableFieldValue, userAllDetailsData) => {
    if (this.props.editable === false && this.props.field_api_name === 'Birth_Date' && 
      userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null  
      && userAllDetailsData[this.props.field_api_name] !== undefined) {
      if(this.props.isMobileVerified || this.props.birthDateFlag) {
        disableFieldValue = 'nonEditable'
      }
      else {
        disableFieldValue = ''
      }
    }
    return disableFieldValue
  }

  onDateClickOutside = () => {
    const allDetails = Object.assign({}, ...this.props.applicationValidation);

    if(allDetails[this.props.field_api_name] === '' && this.state.dateType == 'dd/MM/yyyy') {
      this.setState({showTickmarkForDate: true}, () => {
      })
      // First section validation is incremented as the error message is empty for 'Date of Birth'
      let stagesValues={}
      stagesValues['Birth_Date'] = true;
      this.props.firstSectionValidationUpdate(stagesValues);
    }
    else {
      this.setState({ showTickmarkForDate: false })
      let stagesValues={}
      stagesValues['Birth_Date'] = false;
      this.props.firstSectionValidationUpdate(stagesValues);
    }
  }

  render() {

    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let startYear = '';
    if (this.props.field_api_name === 'date_moved_in_current_address' || this.props.field_api_name === 'time_in_occupation' || this.props.field_api_name ===  'genesis__bank_hours__c') {
      startYear = this.state.startYear;
    } else {
      startYear = 1900;
    }
    let allowValue = this.state.showDev[this.props.field_api_name];
    if (this.props.employmentStatus === true && allowValue === true && this.props.field_api_name === 'time_in_occupation') {
      allowValue = true
    }
    if (this.props.employmentStatus === false && this.props.field_api_name === 'time_in_occupation') {
      allowValue = false;
    }
    let disableValue = ''
    disableValue = this.nonEditableCheckForPrepopulate(disableValue, allDetailData)

    disableValue = this.nonEditableCheckForUserPrepopulate(disableValue, allDetailData)

    disableValue = this.nonEditableCheckForMobileCheck(disableValue, allDetailData)

    if (allowValue === true) {
      return (
        <div className="dynamic-input">
          <div className="form-group">
            <div className='d-flex flex-row'>
              <span>{this.props.field_question}
              {(this.props.flxQuestionMoreInfo) &&
                <a className='fieldTip'>
                  <span>
                      <img src="images/humm-infoIcon.svg" alt="humm-logo" />
                      {this.props.flxQuestionMoreInfo}
                  </span>
                  <img src="images/more-info-icon.svg" alt="info-icon" />
                </a>
              }
              </span>
            </div>
            {this.state.dateType === 'dd/MM/yyyy' &&
              <div>
                <DatePicker selected={allDetailData[this.props.field_api_name] != '' ? this.state.input[this.props.field_api_name] : ''} dateFormat="dd/MM/yyyy" className={`form-control ${disableValue}`} autoComplete="new-password"
                  placeholderText={this.props.field_placeholder} onChange={(date) => { this.setStartDate(date).catch(error => console.error(error)); this.props.fieldChange(this.props.field_api_name, date) }}
                  disabled = { disableValue == 'nonEditable' }
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  strictParsing
                  popperPlacement="auto"
                  dropdownMode="select"
                  onInputClick ={(e) => {analyticsClickEvent(this.props.field_label,'')}}
                />

              {(allDetails[this.props.field_api_name] === '' && this.state.showTickmarkForDate) && 
                <div className='input-group-append-text'>
                  <span>
                    <img src="images/tick-mark.svg" alt="tick icon for validation" 
                    className='validation-tickmark' />
                  </span>
                </div>
              }
              </div>
            }
            {this.state.dateType != 'dd/MM/yyyy' &&
              <DatePicker selected={allDetailData[this.props.field_api_name] != '' ? this.state.input[this.props.field_api_name] : ''} className={`form-control ${disableValue}`} autoComplete="new-password"
                placeholderText={this.props.field_placeholder}
                dateFormat={this.state.dateType}
                onInputClick ={(e) => analyticsClickEvent(this.props.field_label,'')}
                showMonthYearPicker
                maxDate={new Date()}
                showFourColumnMonthYearPicker
                strictParsing
                renderCustomHeader={({
                  date,
                  changeYear,
                }) => (
                  <div>
                    <div className="react-datepicker__header react-datepicker-year-header">{date.getFullYear()}</div>
                    <div className="react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--select">
                      <select id="inputState" className="react-datepicker__year-select" name="datePicker" value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                        {range(startYear, new Date().getFullYear()+1).map((y) =>
                          <option value={y} key={y}>{y}</option>
                        )}
                      </select>
                    </div>
                  </div>
                )}
                onChange={(date) => { this.setStartDate(date).catch(error => console.error(error)); this.props.fieldChange(this.props.field_api_name, date) }}

              />
            }
            <p className="error-message">{allDetails[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
              <span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span>
            }
            </p>
          </div>
        </div>
      );
    } else {
      return (<React.Fragment>  </React.Fragment>);
    }

  }

}

export const mapStateToProps = (state) => {
  return {
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    saveApplicationData: state.dataStore.saveApplicationData,
    saveDates: state.dataStore.saveDates,
    saveDatesMovedIn: state.dataStore.saveDatesMovedIn,
    amountRequested: state.dataStore.amountRequested,
    employmentStatus: state.dataStore.employmentStatus,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    userDetailsPopulateStatus: state.dataStore.userDetailsPopulateStatus,
    birthDateFlag: state.dataStore.birthDateFlag,
    questionValueSave:state.dataStore.questionValueSave,
    firstSectionSteps: state.dataStore.firstSectionSteps,
    isMobileVerified: state.dataStore.isMobileVerified,
    isChangeMobile: state.dataStore.isChangeMobile,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    firstSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FIRST_SECTION_STEPS",
        value: validations
      });
    },
    showappValidation: (validations) => {
      return dispatch({
        type: 'SHOW_APP_VALIDATIONS',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    saveDate: (data) => {
      return dispatch({
        type: 'SAVEDATE',
        value: data
      });
    },
    saveDateMovedIn: (data) => {
      return dispatch({
        type: 'SAVEDATEMOVEDIN',
        value: data
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },
    birthDateFlagStatusCall: (validations) => {
      return dispatch({
        type: 'BIRTH_DATE_FLAG',
        value: validations
      })
    }
  }
}

InputDatePicker.propTypes = {
  field_api_name: PropTypes.string,
  stage: PropTypes.string,
  showAppValidations: PropTypes.string,
  applicationValidation: PropTypes.array,
  saveDate: PropTypes.func,
  saveDates: PropTypes.string,
  appvalidations: PropTypes.func,
  saveDateMovedIn: PropTypes.func,
  saveApplicationDatas: PropTypes.func,
  field_label: PropTypes.string,
  priority: PropTypes.number,
  questionSave: PropTypes.func,
  field_placeholder: PropTypes.string,
  editable: PropTypes.bool,
  birthDateFlag: PropTypes.bool,
  firstSectionValidationUpdate: PropTypes.func,
  fieldChange: PropTypes.func,
  flxQuestionMoreInfo: PropTypes.string,
  saveDatesMovedIn: PropTypes.string,
  field_question: PropTypes.string,
  prepopulateCondition: PropTypes.bool,
  field_api_validations: PropTypes.array,
  prepopulateStatus: PropTypes.bool,
  saveApplicationData: PropTypes.array,
  isMobileVerified: PropTypes.bool,
  userDetailsPopulateStatus: PropTypes.bool,
  employmentStatus: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(InputDatePicker)